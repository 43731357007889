import Image1 from './images/vogue_colors_1.png'
import Image2 from './images/fashion_mood.png'
import Image3 from './images/vogue_colors_2.png'
import Image4 from './images/invertt.png'
import Image5 from './images/eyes_of_the_sun.png'
import Image6 from './images/img_9103.png'
import Image7 from './images/spring_road.png'
import Image8 from './images/fantasy_1.png'
import Image9 from './images/fantasy_2.png'
import Image10 from './images/fantasy_3.png'
import Image11 from './images/snowflake.png'
import Image12 from './images/summer_bowls.png'
import Image13 from './images/cloud_vulcano.png'
import Image14 from './images/the_dance_of_magic.png'
import Image15 from './images/blooming_desire_1.png'
import Image16 from './images/blooming_desire_2.png'
import Image17 from './images/tops.png'

export default {
  collection: [
    {
      index: 0,
      name: 'Vogue Colors',
      source: Image1,
    },
    {
      index: 1,
      name: 'Fashion Mood',
      source: Image2,
    },
    {
      index: 2,
      name: 'Vogue Colors',
      source: Image3,
    },
    {
      index: 3,
      name: 'Invertt',
      source: Image4,
    },
    {
      index: 4,
      name: 'Eye of the Sun',
      source: Image5,
    },
    {
      index: 5,
      name: ' ',
      source: Image6,
    },
    {
      index: 6,
      name: 'Spring Road',
      source: Image7,
    },
    {
      index: 7,
      name: 'Fantasy',
      source: Image8,
    },
    {
      index: 8,
      name: 'Fantasy',
      source: Image9,
    },
    {
      index: 9,
      name: 'Fantasy',
      source: Image10,
    },
    {
      index: 10,
      name: 'Snowflake',
      source: Image11,
    },
    {
      index: 11,
      name: 'Summer Bowls',
      source: Image12,
    },
    {
      index: 12,
      name: 'Cloud Volcano',
      source: Image13,
    },
    {
      index: 13,
      name: 'The Dance of Magic',
      source: Image14,
    },
    {
      index: 14,
      name: 'Blooming Desire',
      source: Image15,
    },
    {
      index: 15,
      name: 'Blooming Desire',
      source: Image16,
    },
    {
      index: 16,
      name: 'Tops',
      source: Image17,
    },
  ],

};
