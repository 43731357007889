import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SophisticatedElegance from "../../components/ArtStyle/SophisticatedElegance"

const SophisticatedElegancePage = () => (
  <Layout>
    <Seo title="Sophisticated Elegance" />
    <SophisticatedElegance />
  </Layout>
)

export default SophisticatedElegancePage
