import React, { Component, useState } from "react"
import Lightbox from "react-image-lightbox"
import images from './images';
import * as styles from './styles.module.scss';

const SophisticatedElegance = () => {
  const { collection } = images
  const initialState = {
    photoIndex: 0,
    isOpen: false,
  };

  const [values, setValues]= useState(initialState);
  const setLightBoxIndex = (index) => {
    setValues({
      ...values,
      selectedIndex: index,
      photoIndex: index,
      isOpen: true,
    });
  };
  return (
    <div className={styles.sophisticatedElegance}>
      <div className={styles.headerContainer}>
        <h1>Sophisticated Elegance</h1>
        <p>
          The new era of elegant interiors is primarily defined by sleek and minimalistic aesthetics.
          This allows the artwork to stand out and create a memorable centerpiece. Smooth lines and
          perfect color combinations in each art piece embody an atmosphere of sophistication and
          finesse.
        </p>
      </div>
      <div className={styles.sophisticatedEleganceGallery}>
        <div>
          <div className={styles.sophisticatedEleganceRow}>
            <div><img alt="gallery image" src={collection[0].source} onClick={() => setLightBoxIndex(0)}/></div>
            <div><img alt="gallery image" src={collection[1].source} onClick={() => setLightBoxIndex(1)}/></div>
            <div><img alt="gallery image" src={collection[2].source} onClick={() => setLightBoxIndex(2)}/></div>
          </div>
          <div className={styles.sophisticatedEleganceRow}>
            <div><img alt="gallery image" src={collection[3].source} onClick={() => setLightBoxIndex(3)}/></div>
            <div><img alt="gallery image" src={collection[4].source} onClick={() => setLightBoxIndex(4)}/></div>
          </div>
          <div className={styles.sophisticatedEleganceRow}>
            <div><img alt="gallery image" src={collection[5].source} onClick={() => setLightBoxIndex(5)}/></div>
            <div><img alt="gallery image" src={collection[6].source} onClick={() => setLightBoxIndex(6)}/></div>
          </div>
          <div className={styles.sophisticatedEleganceRow}>
            <div><img alt="gallery image" src={collection[7].source} onClick={() => setLightBoxIndex(7)}/></div>
            <div><img alt="gallery image" src={collection[8].source} onClick={() => setLightBoxIndex(8)}/></div>
            <div><img alt="gallery image" src={collection[9].source} onClick={() => setLightBoxIndex(9)}/></div>
          </div>
          <div className={styles.sophisticatedEleganceRow}>
            <div><img alt="gallery image" src={collection[10].source} onClick={() => setLightBoxIndex(10)}/></div>
            <div><img alt="gallery image" src={collection[11].source} onClick={() => setLightBoxIndex(11)}/></div>
          </div>
          <div className={styles.sophisticatedEleganceRow}>
            <div><img alt="gallery image" src={collection[12].source} onClick={() => setLightBoxIndex(12)}/></div>
            <div><img alt="gallery image" src={collection[13].source} onClick={() => setLightBoxIndex(13)}/></div>
          </div>
          <div className={styles.sophisticatedEleganceRow}>
            <div><img alt="gallery image" src={collection[14].source} onClick={() => setLightBoxIndex(14)}/></div>
            <div><img alt="gallery image" src={collection[15].source} onClick={() => setLightBoxIndex(15)}/></div>
          </div>
          <div className={styles.sophisticatedEleganceRow}>
            <div><img alt="gallery image" src={collection[16].source} onClick={() => setLightBoxIndex(16)}/></div>
          </div>
        </div>
      </div>
      <div className={styles.sophisticatedEleganceGallery}>
        { values.isOpen && (
          <Lightbox
            imageTitle={collection[values.photoIndex].name}
            mainSrc={collection[values.photoIndex].source}
            nextSrc={collection[(values.photoIndex + 1) % collection.length].source}
            prevSrc={collection[(values.photoIndex + collection.length - 1) % collection.length].source}
            onCloseRequest={() => setValues({...values, isOpen: false})}
            onMovePrevRequest={() =>
              setValues({
                ...values,
                photoIndex: (values.photoIndex + collection.length - 1) % collection.length,
              })
            }
            onMoveNextRequest={() =>
              setValues({
                ...values,
                photoIndex: (values.photoIndex + 1) % collection.length,
              })
            }
          />
        )}
      </div>
    </div>
  );
}

export default SophisticatedElegance;
